import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import Layout from '@/components/layout';
import SEO from '@/components/seo';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />

    <section className="px-4 py-24 mx-auto max-w-7xl">
      <div className="grid items-center w-full grid-cols-1 gap-10 mx-auto md:w-4/5 lg:grid-cols-2 xl:gap-32">
        <div>
          <p className="mb-4 text-lg text-clay font-title font-semibold tracking-wide uppercase">404</p>
          <h1 className="mb-4 text-2xl font-extrabold leading-tight tracking-tight text-left text-gray-900 md:text-4xl">Wut the heck. You are lost...</h1>
          <p className="mb-5 text-base text-left text-gray-800 md:text-xl">You found the goats, but not the page you were looking for.</p>

          <div className="py-4">
            <Link to="/" className="w-full font-title text-sm rounded-sm bg-charcoal text-offwhite px-8 py-4 sm:w-auto sm:mb-0">Back to homepage</Link>
            <Link to="/contact" className="w-full font-title text-sm rounded-sm text-charcoal px-8 py-4 sm:w-auto sm:mb-0">Contact us</Link>
          </div>
        </div>

        <div>
          <StaticImage
            className="w-full h-full py-48 bg-gray-200 rounded-sm max-w-full"
            src="../assets/img/goats.jpeg"
            alt="Goats in Trees"
            layout="constrained"
            style={{ width: '500px', height: '500px' }}
            height={500}
            width={500}
            placeholder="tracedSVG" />
        </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
